import Home from "src/pages/Dashboard/Pages/Home.vue";

import Vue from "vue";
import Router from "vue-router";
import store from "../store";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },

    { path: "*", component: Home },
  ],
});

router.beforeEach((to, from, next) => {
  const currentUser = store.getters.isAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next("/login");
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
