<template>
  <div>
    <auth-layout pageClass="register-page">
      <div class="row d-flex">
        <div class="col-md-12">
          <div class="row" style="margin-top: 50px">
            <div class="col-md-6">
              <div class="mt-5" style="color: white">
                <h1 style="font-weight: 600">HAZARD</h1>
                <p style="font-size: 18px">
                  <a style="color: #095c97; font-size: 20px; font-weight: 600">
                    Hazard (HAZARD)
                  </a>
                  is a tokenomic on hybrid the CeFi + DeFi platform DUO chain
                  with BEP20 (BSC) and ERC20 (Ethereum). It is used in the
                  Information Technology business. Miners generate Proof of
                  Providers(PoPs).In this section we present how these useful
                  proofs can be used to create permissionless network consensus
                  by useful network WIFI and IoT provider.
                </p>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
        <div class="col-md-10">
          <div class="header-text"></div>
        </div>

        <div class="col-md-6">
          <a
            href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x10d28054cba59deea6034291dea3126e207baa62"
            target="_blink"
          >
            <l-button type="primary" wide>Buy Now </l-button>
          </a>
          &nbsp;
          <a
            href="https://bscscan.com/token/0x10d28054cba59deea6034291dea3126e207baa62"
            target="_blink"
          >
            <l-button type="info" wide>BSC scan</l-button>
          </a>
          &nbsp;
          <a
            href="https://etherscan.io/token/0x27d25325d358c30cc41312fa7c24e3f1913caaa1"
            target="_blink"
          >
            <l-button wide>ETHER scan</l-button>
          </a>
          &nbsp;
          <br />
          <br />

          <a
            href="https://dex.guru/token/0x10d28054cba59deea6034291dea3126e207baa62-bsc"
            target="_blink"
          >
            <l-button type="success" wide default>
              <span class="btn-label btn-label-right">
                <img
                  style="max-width: 30px; border: 1px"
                  src="https://avatars.githubusercontent.com/u/72824691?s=280&v=4"
                  alt="..."
                />
              </span>
              <a style="font-weight: 600">
                DEX.GURU
              </a>
            </l-button>
          </a>
          &nbsp;
          <a
            href="https://charts.bogged.finance/?token=0x10D28054CbA59dEeA6034291DEa3126e207BAA62"
            target="_blink"
          >
            <l-button type="success" wide default>
              <span class="btn-label btn-label-right">
                <img
                  style="max-width: 30px; border: 1px"
                  src="https://static.bogged.finance/bsc/assets/0xB09FE1613fE03E7361319d2a43eDc17422f36B09/logo.png"
                  alt="..."
                />
              </span>
              <a style="font-weight: 600">
                CHARTS.BOGGED
              </a>
            </l-button>
          </a>
          &nbsp;
          <a
            href="https://poocoin.app/tokens/0x10d28054cba59deea6034291dea3126e207baa62"
            target="_blink"
          >
            <l-button type="success" wide default>
              <span class="btn-label btn-label-right">
                <img
                  style="max-width: 30px; border: 1px"
                  src="https://poocoin.app/images/logo/poocoin512.png"
                  alt="..."
                />
              </span>
              <a style="font-weight: 600">
                POOCOIN
              </a>
            </l-button>
          </a>
          &nbsp;
        </div>
      </div>

      <div class="row" style="margin-top: 20%; color: white">
        <div>
          <div class="m-2">
            <!-- <a style="font-size: 20px">
              OPEN PREMINE ON：<br />
              Tue Jun 15 2021 23:49:20 GMT+0700 (Indochina Time).
            </a> -->
            <br />
            <br />
            <a target="_blink" href="https://t.me/joinchat/SQbgBNv3TjxkYjU1">
              <l-button type="primary" wide>
                CLICK TO JOIN
                <span class="btn-label btn-label-right">
                  <i class="fa fa-telegram ml-2" style="font-size: 20px"></i>
                </span>
              </l-button>
            </a>
            &nbsp;
            <a target="_blink" href="https://app.hazard.finance/">
              <l-button type="primary" wide>
                FREE WIFI
                <span class="btn-label btn-label-right">
                  <i class="fa fa-wifi ml-2" style="font-size: 20px"></i>
                </span>
              </l-button>
            </a>
            &nbsp;

            <!-- <a
              href="https://bscscan.com/block/countdown/8325005"
              target="_blink"
            >
              <l-button wide>BLOCK COUNTDOWN</l-button>
            </a> -->
            &nbsp;
          </div>
        </div>

        <br />
        <br />
      </div>
    </auth-layout>
    <div class="pb-5" style="background-color: #223848; color: white">
      <div class="pt-5 pb-5">
        <center>
          <a style="font-size: 28px; font-weight: 600">Achievement</a>
          <br />
          <a style="font-size: 22px; font-weight: 600">
            timeline
          </a>
        </center>
      </div>
    </div>

    <div class="pb-5" style="background-color: #223848; color: white">
      <div class="pt-3 pb-3">
        <center>
          <a style="font-size: 28px; font-weight: 600">Coming Soon</a>
          <br />
          <a
            href="https://bscscan.com/token/0x10d28054cba59deea6034291dea3126e207baa62"
            target="_blink"
          >
            <img
              style="max-width: 150px"
              class="m-2"
              src="https://safemoon.net/img/brands/BscScan-logo.png"
              alt="..."
            />
          </a>
          <a
            href="https://etherscan.io/token/0x27d25325d358c30cc41312fa7c24e3f1913caaa1"
            target="_blink"
          >
            <img
              style="max-width: 170px; border: 1px"
              class="m-2"
              src="static/img/etherscan.png"
              alt="..."
            />
          </a>

          <img
            src="https://safemoon.net/img/brands/certik.svg"
            style="max-width: 150px"
            class="m-2"
            alt="..."
          />

          <img
            src="https://safemoon.net/img/brands/nomics.png"
            style="max-width: 150px"
            class="m-2"
            alt="..."
          />

          <img
            src="https://safemoon.net/img/brands/coinmarket.png"
            style="max-width: 150px"
            class="m-2"
            alt="..."
          />

          <img
            src="https://safemoon.net/img/brands/coingecko.png"
            style="max-width: 150px"
            class="m-2"
            alt="..."
          />
          <img
            src="static/img/anchain.png"
            style="max-width: 150px"
            class="m-2"
          />
          <img
            src="static/img/dappradar.png"
            style="max-width: 150px"
            class="m-2"
          />
          <img
            src="static/img/mythx.png"
            style="max-width: 150px"
            class="m-2"
          />
          <img
            src="static/img/rugdoc.png"
            style="max-width: 150px"
            class="m-2"
          />
          <img
            src="static/img/solidity.png"
            style="max-width: 150px"
            class="m-2"
          />
          <img
            src="static/img/techrate.png"
            style="max-width: 150px"
            class="m-2"
          />

          <br />
          <br />
          <br />
          <br />
        </center>

        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="col-6">
              <center>
                <img src="static/img/freewifi.png" style="max-width: 80%" />
              </center>
            </div>
            <div class="col-6">
              <div class=" ">
                <a style="font-size: 22px; font-weight: 600">FREE WIFI</a>
              </div>
            </div>
          </div>
          <div class="row justify-content-center align-items-center ">
            <div class="col-6">
              <center>
                <img src="static/img/mining.png" style="max-width: 80%" />
              </center>
            </div>
            <div class="col-6">
              <div class=" ">
                <a style="font-size: 22px; font-weight: 600">MINING</a>
              </div>
            </div>
          </div>

          <div class="row justify-content-center align-items-center ">
            <div class="col-6">
              <center>
                <img src="static/img/staking.png" style="max-width: 80%" />
              </center>
            </div>
            <div class="col-6">
              <div class=" ">
                <a style="font-size: 22px; font-weight: 600">STAKING</a>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />

        <center>
          <img
            src="static/img/Roadmap-1.png"
            style="max-width: 350px"
            class="m-2"
            alt="..."
          />
          <br />
        </center>

        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <center>
                <img src="static/img/Roadmap-2.png" style="max-width: 95%" />
              </center>
            </div>
            <div class="col-md-7">
              <img src="static/img/Roadmap-3.png" style="max-width: 95%" />
            </div>
          </div>
        </div>
      </div>

      <div style="color: white; background-color: #132939">
        <center>
          <div class="">
            <div class="col-md-6">
              <div class="col-12">
                <div class="row align-items-center">
                  <div class="col-md-6 mt-5">
                    <p style="font-size: 24px; font-weight: 600">
                      Invite Your Friends. Earn Cryptocurrency Together.
                    </p>
                    <a>
                      <a style="font-size: 20px; font-weight: 600">5%</a> Earn a
                      certain commission reward from your friends on HAZARD and
                      from their earnings on Mining & Staking
                    </a>
                    <br />
                    <br />
                    <br />
                    <a
                      v-if="showConnect == false"
                      class="p-3"
                      style="color: white; background-color: #095c97; border: 2px solid ; border-radius: 10px"
                      href="https://app.hazard.finance/"
                    >
                      <a style="font-size: 22px; font-weight: 600">
                        Launch App
                      </a>
                    </a>
                  </div>
                  <div class="col-md-6">
                    <div class="mt-5 mb-4">
                      <div v-if="showConnect || getAddress">
                        <div
                          class="p-4"
                          style="background-color: #192934;   border: 1px solid white;"
                        >
                          <a style="font-size: 20px; font-weight: 600;"
                            >My Referral link
                          </a>
                          <input
                            type="text"
                            disabled
                            placeholder="Affiliate Link"
                            class="form-control"
                            v-model="linkReferal"
                          />
                          <br />
                          <center>
                            <l-button
                              type="primary"
                              wide
                              default
                              @click="copyText()"
                            >
                              Copy Link
                            </l-button>
                          </center>
                          <div class="row">
                            <div class="col-6">
                              <center>
                                <a style="font-size: 12px">You will get </a>
                                <br />
                                <a style="font-size: 22px; font-weight: 600"
                                  >100%
                                </a>
                              </center>
                            </div>
                            <div class="col-6">
                              <center>
                                <a style="font-size: 12px">Friends will get </a>
                                <br />
                                <a style="font-size: 22px; font-weight: 600"
                                  >0 %
                                </a>
                              </center>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <center>
                          <img
                            src="static/img/referal.png"
                            class="mt-5"
                            alt="..."
                            style="max-width: 90%"
                          />
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </center>
      </div>
    </div>

    <div class="pb-2" style="width: 100%;  background-color: #223848;">
      <div class="container">
        <div class="row justify-content-center">
          <a
            class="m-4"
            href="https://www.facebook.com/Hazard.Finance/"
            target="_blink"
          >
            <i class="fa fa-facebook" style="color: white; font-size: 40px"></i>
          </a>

          <a
            class="m-4"
            target="_blink"
            href="https://twitter.com/HazardFinance"
          >
            <i class="fa fa-twitter" style="color: white; font-size: 40px"></i>
          </a>

          <a
            class="m-4"
            href="https://t.me/joinchat/SQbgBNv3TjxkYjU1"
            target="_blink"
          >
            <i class="fa fa-telegram" style="color: white; font-size: 40px"></i>
          </a>

          <a
            class="m-4"
            href="https://www.youtube.com/channel/UCiuQW4zIe2xIv0ftzsCrpqA"
            target="_blink"
          >
            <i class="fa fa-youtube" style="color: white; font-size: 40px"></i>
          </a>

          <a
            class="m-4"
            href="https://medium.com/@hazard.finance"
            target="_blink"
          >
            <i class="fa fa-medium" style="color: white; font-size: 40px"></i>
          </a>
          <a
            class="m-4"
            href="https://hazard.gitbook.io/hazard-whitepaper/tokenomic"
            target="_blink"
          >
            <img
              src="https://assets.website-files.com/600ead1452cf056d0e52dbed/601007da6c6cfea07138fa96_5c349f90a3cd459c6b56455f_logoTextWhite.png"
              style="max-width: 150px"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  FadeRenderTransition,
  Breadcrumb,
  BreadcrumbItem,
} from "src/components/index";

import AuthLayout from "./AuthLayout.vue";
import axios from "axios";

import VueCountdown from "@chenfengyuan/vue-countdown";
import detectEthereumProvider from "@metamask/detect-provider";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
    Breadcrumb,
    BreadcrumbItem,
    VueCountdown,
  },
  data() {
    return {
      priceBTCUSDT: "",
      showConnect: false,
      address: "",
      linkReferal: "https://hazard.finance/",
      ref_address: this.$route.params.address,
    };
  },
  computed: mapGetters(["getAddress"]),

  methods: {
    ...mapActions(["setAddress", "Logout"]),

    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    copyText() {
      const el = document.createElement("textarea");
      el.value = `https://hazard.finance/${this.getAddress}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    async getAddressWallet() {
      const provider = await detectEthereumProvider();

      ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => {
          this.showConnect = true;

          this.setAddress(String(res[0]));
          this.linkReferal = `https://hazard.finance/${res[0]}`;
        })
        .catch((err) => {});
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
  created() {
    if (this.getAddress) {
      this.showConnect = true;
      this.linkReferal = `https://hazard.finance/${this.getAddress}`;
    }
  },
};
</script>

<style></style>
