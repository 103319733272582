<template>
  <div>
    <div class="wrapper wrapper-full-page">
      <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
        <div class="container">
          <div class="navbar-wrapper">
            <a class="navbar-brand" href="/">
              <img
                src="static/img/logo.png"
                style="max-width: 80px; position: relative"
              />
            </a>

            <div class="mr-3">
              <a
                class="p-3"
                style="
                  color: white;
                  background-color: #095c97;
                  border: 2px solid;
                  border-radius: 10px;
                  font-weight: 600
                "
                href="https://app.hazard.finance/"
              >
                Launch App</a
              >
            </div>

            <!-- <button
              class="navbar-toggler"
              type="button"
              @click="showMenu = !showMenu"
              data-toggle="collapse"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-bar burger-lines"></span>
              <span class="navbar-toggler-bar burger-lines"></span>
              <span class="navbar-toggler-bar burger-lines"></span>
            </button> -->
          </div>

          <el-collapse-transition>
            <div
              class="navbar-collapse justify-content-end"
              id="navbar"
              v-show="showMenu"
            >
              <ul class="navbar-nav">
                <a href="https://app.hazard.finance/" tag="li" class="nav-item">
                  <a style="font-weight: 600" class="nav-link">
                    <i class="fa fa-wifi ml-2" style="font-size: 20px"></i>
                    FREE.WIFI</a
                  >
                </a>
              </ul>
            </div>
          </el-collapse-transition>
        </div>
      </nav>
      <div class="full-page" :data-color="backgroundColor" :class="pageClass">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple | black" -->
        <div class="content">
          <div class="container">
            <slot></slot>
          </div>
        </div>

        <div
          class="full-page-background"
          style="background-image: url(static/img/bg3.jpg)"
        ></div>
      </div>
    </div>
    <div class="collapse navbar-collapse off-canvas-sidebar">
      <ul class="nav nav-mobile-menu">
        <router-link to="/register" tag="li">
          <a>Register</a>
        </router-link>
        <router-link to="/auth/overview" tag="li">
          <a>Dashboard</a>
        </router-link>
      </ul>
    </div>
  </div>
</template>
<script>
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import detectEthereumProvider from "@metamask/detect-provider";

import Swal from "sweetalert2";
import Vue from "vue";
import { Dialog, MessageBox } from "element-ui";

import { mapGetters, mapActions } from "vuex";

import auth from "@/api/auth";

Vue.prototype.$confirm = MessageBox.confirm;

export default {
  components: {
    [CollapseTransition.name]: CollapseTransition,
    [Dialog.name]: Dialog,
  },
  props: {
    pageClass: {
      type: String,
      default: "login-page",
    },
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      showMenu: false,
      showConnect: false,
      address: "",
    };
  },
  computed: mapGetters(["getAddress"]),

  methods: {
    ...mapActions(["setAddress", "Logout"]),
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    async getAddressWallet() {
      const provider = await detectEthereumProvider();

      ethereum
        .request({ method: "eth_requestAccounts" })
        .then(async (res) => {
          Swal.fire({
            title: "Select Login",
            html:
              '<div class="form-group">' +
              '<div style="width: 100%">' +
              ' <img style="max-width: 90%; border: 1px; boder-color: red" src="https://cdn6.aptoide.com/imgs/4/c/7/4c778fab294acfb49ba884eeaf1f72ce_fgraphic.png"/>' +
              "</div>" +
              "</div>",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          })
            .then(function(result) {
              Swal.fire({
                type: "success",
                html: "You entered",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
            })
            .catch();
          // this.address =
          //   res[0].substr(0, 4) +
          //   "..." +
          //   res[0].substr(res[0].length - 4, res[0].length);
          // this.showConnect = true;
          // this.setAddress(String(res[0]));
          // await auth
          //   .signUp({
          //     address: res[0],
          //     ref: this.ref,
          //   })
          //   .then(() => {
          //     this.$router.push("/login");
          //   })
          //   .catch((error) => {
          //     console.log(`${error.response.data.error.message}`);
          //   });
        })
        .catch((err) => {});
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
  created() {
    // this.Logout();
    if (this.getAddress) {
      this.address =
        this.getAddress.substr(0, 4) +
        "..." +
        this.getAddress.substr(
          this.getAddress.length - 4,
          this.getAddress.length
        );
      this.showConnect = true;
    }
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.navbar-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.wrapper-full-page .navbar-toggler,
.wrapper-full-page .navbar-collapse .navbar-nav {
  margin-right: 30px;
}
.navbar-collapse .navbar-nav .nav-link {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
